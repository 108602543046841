import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";

function ThankYou() {
  return (
    <Layout>
      <SEO title={"Thank you"}></SEO>
      <h1>Thank you!</h1>
      <p>I'm not quite sure how you got here but I definitely do know that you sent me something. It could be a sign up for my newsletter or a message via the contact form - for both, kudos to you! Or virtual brownie points!</p>
      <p>But yeah! Thank you! You are awesome!</p>
    </Layout>
  )
}

export default ThankYou
